import ts from '@client/common/ts'


export default {

  namespaced: true,
  state: {
    windowWidth: window.innerWidth,
    aside: window.innerWidth < ts.conf.base.mobile_breakpoint ? false : true
  },
  getters: {
    windowWidth:    state => state.windowWidth,
    isMediumDevice: state => state.windowWidth < ts.conf.base.mobile_breakpoint ? true : false,
    asideOpen:      state => state.aside
  },
  mutations: {
    windowChanged(state) {
      state.windowWidth = window.innerWidth
      state.aside = state.windowWidth < ts.conf.base.mobile_breakpoint ? false : true
    },
    toggleAside(state) {
      state.aside = !state.aside
    },
    openAside(state) {
      state.aside = true
    },
    closeAside(state) {
      state.aside = false
    }
  },
  actions: {
    windowChanged: ({ commit }) => {
      commit('windowChanged')
    },
    toggleAside: ({ commit }) => {
      commit('toggleAside')
    },
    openAside: ({ commit }) => {
      commit('openAside')
    },
    closeAside: ({ commit }) => {
      commit('closeAside')
    }
  }

}
