import apiOrganizations from '@client/services/api/me/organizations'


export default {

  namespaced: true,
  actions: {
    getBoard: (_, payload) => {
      return apiOrganizations.get(payload)
    }
  }

}
