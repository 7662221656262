import api   from '@client/services/api'
import axios from '@client/services/axios/interceptors'


export default {

  board_account(query, config) {
    return api.get(axios, '/manager/base/board_account', {}, query, config)
  },

  board_accounts(query, config) {
    return api.get(axios, '/manager/base/board_accounts', {}, query, config)
  },

  board_organizations(query, config) {
    return api.get(axios, '/manager/base/board_organizations', {}, query, config)
  },

  board_coupons(query, config) {
    return api.get(axios, '/manager/base/board_coupons', {}, query, config)
  }

}
