import Vue     from 'vue'
import Vuex    from 'vuex'
import cache   from 'vuex-cache'
import ts      from '@client/common/ts'
import base    from '@client/store/frontend/base'
import auth    from '@client/store/frontend/auth'
import ui      from '@client/store/frontend/ui'
import account from '@client/store/frontend/account'
import me      from '@client/store/frontend/me'
import manager from '@client/store/frontend/manager'


Vue.use(Vuex)


const store = new Vuex.Store({
  plugins: [
    cache({ timeout: ts.conf.cache.client.store.default || 900000 })
  ],
  modules: {
    base,
    auth,
    ui,
    account,
    me,
    manager
  }
})


export default store
