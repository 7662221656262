import apiConfig from '@client/services/api/utils/config'


let Vue


class Config {

  constructor(options = { conf: {} }) {
    this.options = options
    this.parse()
  }

  parse() {
    try {
      this.conf = JSON.parse(document.getElementById(this.options.conf.id).innerHTML).conf
    } catch(e) {
      this.conf = {}
    }
  }

  load(query = this.options.conf.query) {
    return apiConfig.load({ utils: { query: query || 'all' } }).promise
      .then(response => {
        return response.data.attributes.load
      })
  }

}


function install(_Vue) {

  if (Vue && _Vue === Vue) return
  Vue = _Vue

  Vue.mixin({
    beforeCreate() {
      const options = this.$options
      if (options.ts) {
        this.$ts = options.ts
      } else if (options.parent && options.parent.$ts) {
        this.$ts = options.parent.$ts
      }
    }
  })

}


export default {
  Config,
  install
}
