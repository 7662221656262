import api   from '@client/services/api'
import axios from '@client/services/axios/interceptors'


export default {

  list(params, query, config) {
    return api.get(axios, '/me/organizations', params, query, config)
  },

  get(params, query, config) {
    return api.get(axios, '/me/organizations/:id', params, query, config)
  }

}
