import api   from '@client/services/api'
import axios from '@client/services/axios/interceptors'


export default {

  list(params, query, config) {
    return api.get(axios, '/me/coupons', params, query, config)
  },

  get(params, query, config) {
    return api.get(axios, '/me/coupons/:id', params, query, config)
  },

  token(params, query, config) {
    return api.get(axios, '/me/coupons/:id/token', params, query, config)
  },

  verify(payload, config) {
    return api.post(axios, '/me/coupons/verify', {}, payload, config)
  },

  redeem(payload, config) {
    return api.update(axios, '/me/coupons/redeem', {}, payload, config)
  }

}
