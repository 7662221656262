import { asyncLoadRouter } from '@client/router/helper'


const organizations = [
  {
    path: 'organizations',
    component: asyncLoadRouter('me/Organizations'),
    meta: {
      breadcrumb: i18n => [ { text: i18n.t('activerecord.models.organization.other'), to: { name: 'me-organizations' } } ]
    },
    children: [
      {
        path: '',
        name: 'me-organizations',
        component: asyncLoadRouter('me/organizations/List'),
        meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.list') } ] },
        props: { action: 'list' }
      },
      {
        path: ':organization_id',
        name: 'me-organizations-show',
        component: asyncLoadRouter('me/organizations/Show'),
        meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('shared.actions.show'), to: { name: 'me-organizations-show', params: { organization_id: parseInt(params.organization_id) } } } ] },
        props: { action: 'show' }
      }
    ]
  }
]


export default organizations
