import { asyncLoadRouter } from '@client/router/helper'


const members = [
  {
    path: 'members',
    component: asyncLoadRouter('manager/Members'),
    meta: {
      requiresAuth: true,
      authorize: ['admin', 'manager', 'admin_scp::organization::', 'manager_scp::organization::'],
      breadcrumb: i18n => [ { text: i18n.t('activerecord.models.member.other'), to: { name: 'manager-members' } } ]
    },
    children: [
      {
        path: '',
        name: 'manager-members',
        component: asyncLoadRouter('manager/members/List'),
        meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.list') } ] },
        props: { action: 'list' },
      },
      {
        path: 'new',
        name: 'manager-members-new',
        component: asyncLoadRouter('manager/members/New'),
        meta: {
          authorize: ['admin', 'manager'],
          breadcrumb: i18n => [ { text: i18n.t('shared.actions.new'), to: { name: 'manager-members-new' } } ]
        },
        props: { action: 'create' }
      },
      {
        path: ':member_id',
        redirect: to => { return { name: 'manager-members-edit', params: { locale: to.params.locale, member_id: parseInt(to.params.member_id) } } },
        name: 'manager-members-show',
        component: asyncLoadRouter('manager/members/Show'),
        meta: { breadcrumb: (i18n, params) => [ { text: `ID ${parseInt(params.member_id)}`, to: { name: 'manager-members-edit', params: { member_id: parseInt(params.member_id) } } } ] },
        props: { action: 'show' },
        children: [
          {
            path: 'edit',
            name: 'manager-members-edit',
            component: asyncLoadRouter('manager/members/Form'),
            meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.edit') } ] },
            props: { action: 'update' }
          },
          {
            path: 'address/edit',
            name: 'manager-members-address-edit',
            component: asyncLoadRouter('manager/members/address/Form'),
            meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('activerecord.models.address.one'), to: { name: 'manager-members-address-edit', params: { member_id: parseInt(params.member_id) } } },
                                                    { text: i18n.t('shared.actions.edit') } ] },
            props: { action: 'update' }
          },
          {
            path: 'contact/edit',
            name: 'manager-members-contact-edit',
            component: asyncLoadRouter('manager/members/contact/Form'),
            meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('activerecord.models.contact.one'), to: { name: 'manager-members-contact-edit', params: { member_id: parseInt(params.member_id) } } },
                                                    { text: i18n.t('shared.actions.edit') } ] },
            props: { action: 'update' }
          },
          {
            path: 'allocations',
            component: asyncLoadRouter('manager/members/Allocations'),
            meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('activerecord.models.allocation.other'), to: { name: 'manager-members-allocations', params: { member_id: parseInt(params.member_id) } } } ] },
            children: [
              {
                path: '',
                name: 'manager-members-allocations',
                component: asyncLoadRouter('manager/members/allocations/List'),
                meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.list') } ] },
                props: { action: 'list' },
              },
              {
                path: 'new',
                name: 'manager-members-allocations-new',
                component: asyncLoadRouter('manager/members/allocations/Form'),
                meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('shared.actions.new'), to: { name: 'manager-members-allocations-new', params: { member_id: parseInt(params.member_id) } } } ] },
                props: { action: 'create' }
              },
              {
                path: ':id/edit',
                name: 'manager-members-allocations-edit',
                component: asyncLoadRouter('manager/members/allocations/Form'),
                meta: { breadcrumb: (i18n, params) => [ { text: `ID ${parseInt(params.id)}`, to: { name: 'manager-members-allocations-edit', params: { member_id: parseInt(params.member_id), id: parseInt(params.id) } } },
                                                        { text: i18n.t('shared.actions.edit') } ] },
                props: { action: 'update' }
              }
            ]
          },
          {
            path: 'identity-card/edit',
            name: 'manager-members-identity-card-edit',
            component: asyncLoadRouter('manager/members/identity_card/Form'),
            meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('activerecord.models.identity_card.one'), to: { name: 'manager-members-identity-card-edit', params: { member_id: parseInt(params.member_id) } } },
                                                    { text: i18n.t('shared.actions.edit') } ] },
            props: { action: 'update' }
          },
          {
            path: 'identity-passport/edit',
            name: 'manager-members-identity-passport-edit',
            component: asyncLoadRouter('manager/members/identity_passport/Form'),
            meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('activerecord.models.identity_passport.one'), to: { name: 'manager-members-identity-passport-edit', params: { member_id: parseInt(params.member_id) } } },
                                                    { text: i18n.t('shared.actions.edit') } ] },
            props: { action: 'update' }
          }
        ]
      }
    ]
  }
]


export default members
