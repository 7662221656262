import Noty from 'noty'
import 'noty/src/noty.scss'
import 'noty/src/themes/bootstrap-v4.scss'
import '@client/assets/css/plugins/noty.scss'


const defaults = {
  layout: 'bottomRight',
  force: true,
  theme: 'bootstrap-v4',
  template: 'simple',
  icon: 'fal fa-fw fa-2x fa-chevron-circle-right',
  timeout: 5000,
  progressBar: true,
  closeWith: ['click'],
}


const VueNoty = {
  options: {},

  setOptions(options) {
    this.options = { ...defaults, ...options }
    return this

  },

  show(text, type = 'alert', options = {}) {
    const params = { ...this.options, ...options, ...{
        type,
        text
      }
    }
    return new Noty(params).show()
  },

  alert(text, options = {}) {
    const opts = { icon: 'fal fa-fw fa-2x fa-chevron-circle-right', ...options }
    return this.show(text, 'alert', opts)
  },

  success(text, options = {}) {
    const opts ={ icon: 'fal fa-fw fa-2x fa-check-circle', ...options }
    return this.show(text, 'success', opts)
  },

  danger(text, options = {}) {
    const opts = { icon: 'fal fa-fw fa-2x fa-times-circle', ...options }
    return this.show(text, 'error', opts)
  },

  warning(text, options = {}) {
    const opts = { icon: 'fal fa-fw fa-2x fa-exclamation-circle', ...options }
    return this.show(text, 'warning', opts)
  },

  info(text, options = {}) {
    const opts = { icon: 'fal fa-fw fa-2x fa-info-circle', ...options }
    return this.show(text, 'info', opts)
  }
}


export default {
  install: function (Vue, options) {
    const noty = VueNoty.setOptions(options)
    Vue.prototype.$noty = noty
    Vue.noty = noty
  }
}
