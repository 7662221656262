import { asyncLoadRouter } from '@client/router/helper'
import account       from '@client/router/frontend/manager/account'
import accounts      from '@client/router/frontend/manager/accounts'
import organizations from '@client/router/frontend/manager/organizations'
import members       from '@client/router/frontend/manager/members'
import coupons       from '@client/router/frontend/manager/coupons'


const manager = [
  {
    path: 'manager',
    component: asyncLoadRouter('Manager'),
    meta: { requiresAuth: true, authorize: ['admin', 'manager', 'admin_scp::organization::', 'manager_scp::organization::'] },
    children: [
      {
        path: '',
        name: 'manager-dashboard',
        component: asyncLoadRouter('manager/Dashboard'),
        meta: { breadcrumb: i18n => [ { text: i18n.t('shared.menu.dashboard'), to: { name: 'manager-dashboard' } } ] },
      },
      ...account,
      ...accounts,
      ...organizations,
      ...members,
      ...coupons
    ]
  }
]


export default manager
