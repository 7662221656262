import { asyncLoadRouter } from '@client/router/helper'


const coupons = [
  {
    path: 'coupons',
    component: asyncLoadRouter('manager/Coupons'),
    meta: {
      requiresAuth: true,
      authorize: ['admin', 'manager', 'admin_scp::organization::', 'manager_scp::organization::'],
      breadcrumb: i18n => [ { text: i18n.t('activerecord.models.coupon.other'), to: { name: 'manager-coupons' } } ]
    },
    children: [
      {
        path: 'categories',
        component: asyncLoadRouter('manager/coupons/Categories'),
        meta: {
          authorize: ['admin'],
          breadcrumb: i18n => [ { text: i18n.t('activerecord.models.coupon_category.other'), to: { name: 'manager-coupon-categories' } } ]
        },
        children: [
          {
            path: '',
            name: 'manager-coupon-categories',
            component: asyncLoadRouter('manager/coupons/categories/List'),
            meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.list') } ] },
            props: { action: 'list' },
          },
          {
            path: 'new',
            name: 'manager-coupon-categories-new',
            component: asyncLoadRouter('manager/coupons/categories/Form'),
            meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.new'), to: { name: 'manager-coupon-categories-new' } } ] },
            props: { action: 'create' }
          },
          {
            path: ':id/edit',
            name: 'manager-coupon-categories-edit',
            component: asyncLoadRouter('manager/coupons/categories/Form'),
            meta: { breadcrumb: (i18n, params) => [ { text: `ID ${parseInt(params.id)}`, to: { name: 'manager-coupon-categories-edit', params: { id: parseInt(params.id) } } },
                                                    { text: i18n.t('shared.actions.edit') } ] },
            props: { action: 'update' }
          }
        ]
      },
      {
        path: '',
        name: 'manager-coupons',
        component: asyncLoadRouter('manager/coupons/List'),
        meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.list') } ] },
        props: { action: 'list' }
      },
      {
        path: 'new',
        name: 'manager-coupons-new',
        component: asyncLoadRouter('manager/coupons/New'),
        meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.new'), to: { name: 'manager-coupons-new' } } ] },
        props: { action: 'create' }
      },
      {
        path: ':coupon_id',
        redirect: to => { return { name: 'manager-coupons-edit', params: { locale: to.params.locale, coupon_id: parseInt(to.params.coupon_id) } } },
        name: 'manager-coupons-show',
        component: asyncLoadRouter('manager/coupons/Show'),
        meta: { breadcrumb: (i18n, params) => [ { text: `ID ${parseInt(params.coupon_id)}`, to: { name: 'manager-coupons-edit', params: { coupon_id: parseInt(params.coupon_id) } } } ] },
        props: { action: 'show' },
        children: [
          {
            path: 'edit',
            name: 'manager-coupons-edit',
            component: asyncLoadRouter('manager/coupons/Form'),
            meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.edit') } ] },
            props: { action: 'update' }
          },
          {
            path: 'details',
            redirect: to => { return { name: 'manager-coupons-details-edit', params: { locale: to.params.locale, coupon_id: parseInt(to.params.coupon_id) } } },
            component: asyncLoadRouter('manager/coupons/Details'),
            meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('activerecord.models.coupon_detail.other'), to: { name: 'manager-coupons-details-edit', params: { coupon_id: parseInt(params.coupon_id) } } } ] },
            children: [
              {
                path: 'edit',
                name: 'manager-coupons-details-edit',
                component: asyncLoadRouter('manager/coupons/details/Form'),
                meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.edit') } ] },
                props: { action: 'update' }
              }
            ]
          },
          {
            path: 'allocations',
            redirect: to => { return { name: 'manager-coupons-allocations-edit', params: { locale: to.params.locale, coupon_id: parseInt(to.params.coupon_id) } } },
            component: asyncLoadRouter('manager/coupons/Allocations'),
            meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('activerecord.models.coupon_allocation.other'), to: { name: 'manager-coupons-allocations-edit', params: { coupon_id: parseInt(params.coupon_id) } } } ] },
            children: [
              {
                path: 'edit',
                name: 'manager-coupons-allocations-edit',
                component: asyncLoadRouter('manager/coupons/allocations/Form'),
                meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.edit') } ] },
                props: { action: 'update' }
              }
            ]
          }
        ]
      }
    ]
  }
]


export default coupons
