import api   from '@client/services/api'
import axios from '@client/services/axios/instance'


export default {

  load(query, config) {
    return api.post(axios, '/utils/config', {}, query, config)
  }

}
