import { placeholders } from '@client/common/placeholders'


export default {

  get(axios, resource, params, query = {}, config = {}) {
    const source = axios.CancelToken.source()
    const promise = axios.get(placeholders(resource, params), { ...config, cancelToken: source.token, params: query })
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.error(`[TS] Api GET error! ${error}.`)
        return Promise.reject(config.responseErrorRaw ? error : error.response ? error.response.data : error.request ? error.request.data : error.data)
      });
    return { promise, source }
  },

  post(axios, resource, params, payload, config = {}) {
    const source = axios.CancelToken.source()
    const promise = axios.post(placeholders(resource, params), payload, { ...config, cancelToken: source.token })
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.error(`[TS] Api POST error! ${error}.`)
        return Promise.reject(config.responseErrorRaw ? error : error.response ? error.response.data : error.request ? error.request.data : error.data)
      });
    return { promise, source }
  },

  update(axios, resource, params, payload, config = {}) {
    const source = axios.CancelToken.source()
    const promise = axios.put(placeholders(resource, params), payload, { ...config, cancelToken: source.token })
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.error(`[TS] Api PUT error! ${error}.`)
        return Promise.reject(config.responseErrorRaw ? error : error.response ? error.response.data : error.request ? error.request.data : error.data)
      });
    return { promise, source }
  },

  delete(axios, resource, params, config = {}) {
    const source = axios.CancelToken.source()
    const promise = axios.delete(placeholders(resource, params), { ...config, cancelToken: source.token })
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.error(`[TS] Api DELETE error! ${error}.`)
        return Promise.reject(config.responseErrorRaw ? error : error.response ? error.response.data : error.request ? error.request.data : error.data)
      });
    return { promise, source }
  }

}
