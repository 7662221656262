import Account    from '@client/models/account'
import apiAccount from '@client/services/api/account'


export default {

  namespaced: true,
  state: {
    account: null
  },
  getters: {
    currentAccount:     state => state.account,
    isSubscriber:       state => state.account && state.account.rol.includes('subscriber'),
    isAdmin:            state => state.account && state.account.rol.includes('admin'),
    isManager:          state => state.account && state.account.rol.includes('manager'),
    isAM:               state => state.account && state.account.rol.some(v => ['admin', 'manager'].includes(v)),
    isContributor:      state => state.account && state.account.rol.includes('contributor'),
    isAdminOrg:         state => state.account && state.account.rol.includes('admin_scp::organization::'),
    isManagerOrg:       state => state.account && state.account.rol.includes('manager_scp::organization::'),
    isAMOrg:            state => state.account && state.account.rol.some(v => ['admin_scp::organization::', 'manager_scp::organization::'].includes(v)),
    isContributorOrg:   state => state.account && state.account.rol.includes('contributor_scp::organization::'),
    isAdminOrgBy:       state => id => state.account && state.account.rol.includes(`admin_scp::organization::${id}`),
    isManagerOrgBy:     state => id => state.account && state.account.rol.includes(`manager_scp::organization::${id}`),
    isAMOrgBy:          state => id => state.account && state.account.rol.some(v => [`admin_scp::organization::${id}`, `manager_scp::organization::${id}`].includes(v)),
    isContributorOrgBy: state => id => state.account && state.account.rol.includes(`contributor_scp::organization::${id}`)
  },
  mutations: {
    login(state, data) {
      state.account = Account.from(data)
    },
    logout(state) {
      state.account = null
    },
    userinfo(state, data) {
      state.account = Account.from(data)
    }
  },
  actions: {
    login: ({ commit }, data) => {
      commit('login', data)
    },
    logout: ({ commit }) => {
      commit('logout')
    },
    userinfo: ({ commit }) => {
      return apiAccount.userinfo().promise
        .then(response => {
          commit('userinfo', response.data)
        })
   }
  }

}
