import api   from '@client/services/api'
import axios from '@client/services/axios/interceptors'


export default {

  board(query, config) {
    return api.get(axios, '/me/base/board', {}, query, config)
  }

}
