import Vue               from 'vue'
import BootstrapVue      from 'bootstrap-vue'
import * as Sentry       from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import ts                from '@client/common/ts'
import i18n              from '@client/common/i18n'
import router            from '@client/router/frontend'
import store             from '@client/store/frontend'
import frontend          from '@client/views/frontend'
import nprogress         from '@client/common/nprogress'
import resize            from '@client/common/resize'
import deep              from '@client/common/deep'
import alerts            from '@client/common/alerts'
import notifications     from '@client/common/notifications'
import currencyInput     from '@client/common/currencyInput'
//import treeview          from '@client/common/treeview'
import { sync }          from 'vuex-router-sync'
import '@client/assets/css/plugins/fontawesome.scss'
import '@client/assets/css/plugins/bootstrap/config.scss'
import '@server/node_modules/bootstrap/scss/bootstrap.scss'
import '@server/node_modules/bootstrap-vue/dist/bootstrap-vue.css'
import '@client/assets/css/app.scss'
import '@server/node_modules/nprogress/nprogress.css'
import '@server/node_modules/flag-icon-css/sass/flag-icon.scss'
import '@server/node_modules/vue-content-placeholders/src/styles.scss'
import '@server/node_modules/sweetalert2/dist/sweetalert2.min.css'


/* https://caniuse.com/#feat=mdn-javascript_builtins_array_flat */
if (typeof Array.prototype.flat !== 'function') {
  Object.defineProperty(Array.prototype, 'flat', {
      value: function(depth = Infinity) {
        return this.reduce(function (flat, toFlatten) {
          return flat.concat((Array.isArray(toFlatten) && (depth>1)) ? toFlatten.flat(depth-1) : toFlatten);
        }, []);
      }
  });
}


/* Add Sentry tracker. */
if (process.env.SENTRY_FRONTEND_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_FRONTEND_DSN,
    environment: process.env.STAGE,
    integrations: [new Integrations.Vue({Vue, attachProps: true})]
  })
  /**
   * Add debug: true to init or capture an error to test Sentry.
   * Sentry.captureException(new Error('This is a test error!'))
   */
}


/*
 * Expose a factory function that creates a fresh set of store, router,
 * app instances on each call (which is called for each SSR request)
 */
export function createApp() {
  sync(store, router)

  Vue.use(BootstrapVue)
  Vue.config.productionTip = false

  /* Load components globally */
  Vue.component('loading', () => import('@client/components/frontend/shared/PsLoader'))

  return new Vue({
    ts,
    i18n,
    router,
    store,
    nprogress,
    render: h => h(frontend)
  })
}


/* Export created app. */
export const app = createApp()


/* Load app after document ready. */
document.addEventListener('DOMContentLoaded', () => {
  app.$mount('#app')
})


/* Load service worker for PWA. */
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register(`/service-worker.js${localStorage.locale ? `?locale=${localStorage.locale}` : ''}`)
      /*.then((reg) => {
        console.log('[Serviceworker]', 'Service worker registered!', reg);
      });*/
  });
}
