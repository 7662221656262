import api   from '@client/services/api'
import axios from '@client/services/axios/interceptors'


export default {

  list(params, query, config) {
    return api.get(axios, '/manager/coupons', params, query, config)
  },

  get(params, query, config) {
    return api.get(axios, '/manager/coupons/:id', params, query, config)
  },

  create(params, payload, config) {
    return api.post(axios, '/manager/coupons', params, payload, config)
  },

  update(params, payload, config) {
    return api.update(axios, '/manager/coupons/:id', params, payload, config)
  },

  destroy(params, config) {
    return api.delete(axios, '/manager/coupons/:id', params, config)
  },

  board(params, query, config) {
    return api.get(axios, '/manager/coupons/:id/board', params, query, config)
  },

  searchCouponCategories(params, query, config) {
    return api.get(axios, '/manager/coupons/search_coupon_categories', params, query, config)
  },

  searchOrganizations(params, query, config) {
    return api.get(axios, '/manager/coupons/search_organizations', params, query, config)
  }

}
