import store    from '@client/store/frontend'
import router   from '@client/router/frontend'
import instance from '@client/services/axios/instance'


/* Request interceptor. */
instance.interceptors.request.use(config => {
  config.headers.common['Accept-Language'] = store.getters['base/getLocale']
  return config
})


/* Response interceptor. */
instance.interceptors.response.use(response => {
  if (response.data && response.data.meta && response.data.meta.redirect) {
    response.data.meta.redirect.remote ? window.location.replace(response.data.meta.redirect.remote) : router.push(response.data.meta.redirect)
  }
  return Promise.resolve(response);
}, error => {
    /* The request was made and the server responded with a status code. */
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch('auth/logout')
        router.push({ name: 'login', params: { locale: router.app.$store.getters['base/getLocale'] } })
      } else if (error.response.data && error.response.data.meta && error.response.data.meta.redirect) {
        router.push(error.response.data.meta.redirect)
      }
    /* The request was made but no response was received. */
    } else if (error.request) {
      error.request.data = { meta: { notices: [ { id: (new Date() / 100000), type: 'danger', title: 'Error', detail: 'The request was made but no response was received.' } ] } }
    /* Something happened in setting up the request. */
    } else {
      error.data = { meta: { notices: [ { id: (new Date() / 100000), type: 'danger', title: 'Error', detail: 'Something happened performing the request.' } ] } }
    }
    return Promise.reject(error)
})


export default instance
