import { asyncLoadRouter } from '@client/router/helper'


const allocations = [
  {
    path: 'allocations',
    component: asyncLoadRouter('me/Allocations'),
    meta: {
      authorize: ['subscriber'],
      breadcrumb: i18n => [ { text: i18n.t('activerecord.models.allocation.other'), to: { name: 'me-allocations' } } ]
    },
    children: [
      {
        path: '',
        name: 'me-allocations',
        component: asyncLoadRouter('me/allocations/List'),
        meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.list') } ] },
        props: { action: 'list' }
      }
    ]
  }
]


export default allocations
