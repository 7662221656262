import { asyncLoadRouter } from '@client/router/helper'
import coupons       from '@client/router/frontend/me/coupons'
import allocations   from '@client/router/frontend/me/allocations'
import organizations from '@client/router/frontend/me/organizations'
import account       from '@client/router/frontend/me/account'


const me = [
  {
    path: 'me',
    component: asyncLoadRouter('Me'),
    meta: {
      requiresAuth: true,
      breadcrumb: i18n => [ { text: i18n.t('shared.my'), to: { name: 'me-dashboard' } } ]
    },
    children: [
      {
        path: '',
        name: 'me-dashboard',
        component: asyncLoadRouter('me/Dashboard'),
        meta: {
          breadcrumb: i18n => [ { text: i18n.t('shared.team_card'), to: { name: 'me-dashboard' } } ]
        }
      },
      ...coupons,
      ...allocations,
      ...organizations,
      ...account
    ]
  }
]


export default me
