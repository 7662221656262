import Vue            from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'


Vue.use(VueSweetalert2, {
  buttonsStyling: false,
  customClass: {
		confirmButton: 'btn btn-primary',
		cancelButton: 'btn btn-tsindian'
	}
})
