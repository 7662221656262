import axios  from 'axios'


/* Create axios instance. */
const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.API_URL,
  headers: {
    'TS-Client': process.env.AUD,
    'Accept': 'application/vnd.api+json, application/vnd.teamsoelden.v1+json',
    'Content-Type': 'application/json'
  }
})


/* Add cancelToken property. */
instance.CancelToken = axios.CancelToken


export default instance
