<template>
  <component :is="layout" class="ts">
    <router-view :layout.sync="layout"/>
    <resize-observer v-if="baseLayout" @notify="handleResize"/>
  </component>
</template>


<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'frontend',
    computed: {
      baseLayout() {
        return this.layout === 'div'
      }
    },
    methods: {
      ...mapActions('ui', { windowChanged: 'windowChanged' }),
      handleResize() {
        clearTimeout(this.timer.resize)
        this.timer.resize = setTimeout(() => { this.windowChanged() }, 500)
      }
    },
    data() {
      return {
        layout: 'div',
        timer: {}
      };
    },
    beforeCreate() {
      this.$nprogress.settings.showSpinner = false
    }
  }
</script>


<style lang="scss">
  body #nprogress {
    .bar {
      background: $ts-indian;
    }
    .peg {
      box-shadow: 0 0 10px $ts-indian, 0 0 5px $ts-indian;
    }
  }
</style>
