import ts                from '@client/common/ts'
import i18n              from '@client/common/i18n'
import apiI18n           from '@client/services/api/utils/i18n'


function defineDefaultLocale() {
  let locale = localStorage.locale || navigator.language.substr(0,2) || ts.conf.base.default_locale
  if (!ts.conf.base.available_locales.includes(locale)) locale = ts.conf.base.default_locale
  return locale
}


export default {

  namespaced: true,
  state: {
    locale: {
      active: defineDefaultLocale(),
      loaded: Object.assign(...ts.conf.base.available_locales.map(k => ({ [k]: [] })))
    }
  },
  getters: {
    getLocale:        state => state.locale.active,
    getLocalesLoaded: state => state.locale.loaded
  },
  mutations: {
    setLocale(state, value) {
      localStorage.locale = value
      state.locale.active = value
      i18n.locale = value
    },
    loadLocale(state, locale) {
      for (let load in locale.data) {
        i18n.mergeLocaleMessage(load, locale.data[load])
        state.locale.loaded[load] = [...new Set([ ...state.locale.loaded[load], ...locale.payload.query ])]
      }
    }
  },
  actions: {
    setLocale: ({ commit }, data) => {
      if (!ts.conf.base.available_locales.includes(data)) data = defineDefaultLocale()
      commit('setLocale', data)
    },
    loadLocale: ({ commit }, payload) => {
      return apiI18n.load({ utils: payload }).promise
        .then(response => {
          commit('loadLocale', { payload: payload, data: response.data.attributes.load })
        })
    }
  }

}
