import accounts      from '@client/store/frontend/manager/accounts'
import organizations from '@client/store/frontend/manager/organizations'
import members       from '@client/store/frontend/manager/members'
import coupons       from '@client/store/frontend/manager/coupons'
import apiManager    from '@client/services/api/manager'


export default {

  namespaced: true,
  modules: {
    accounts,
    organizations,
    members,
    coupons
  },
  actions: {
    getBoardAccount: (_, payload) => {
      return apiManager.board_account()
    },
    getBoardAccounts: (_, payload) => {
      return apiManager.board_accounts()
    },
    getBoardOrganizations: (_, payload) => {
      return apiManager.board_organizations()
    },
    getBoardCoupons: (_, payload) => {
      return apiManager.board_coupons()
    }
  }

}
