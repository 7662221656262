import Vue       from 'vue'
import VueRouter from 'vue-router'
import ts        from '@client/common/ts'
import i18n      from '@client/common/i18n'
import routes    from '@client/router/frontend/routes'


Vue.use(VueRouter)


const router = new VueRouter({
  mode: 'history',
  fallback: false,
  scrollBehavior: () => ({ y: 0 }),
  linkExactActiveClass: 'active',
  routes
})


/* Check if locale basic translations are loaded. */
function checkLocaleLoaded(locale) {
  if (!router.app.$store.getters['base/getLocalesLoaded'][locale].length) {
    return router.app.$store.dispatch('base/loadLocale', { locale: locale, query: '.' })
  }
  return Promise.resolve()
}


/* Check if requested locale is set. */
function checkLocaleSet(locale) {
  if (localStorage.locale !== locale || i18n.locale !== locale ) {
    router.app.$store.cache.clear()
    return router.app.$store.dispatch('base/setLocale', locale)
  }
}


/* Check if route requires authentication. */
function checkUserinfo(to) {
  if (!router.app.$store.getters['auth/currentAccount']) {
    return router.app.$store.dispatch('auth/userinfo')
  }
  return Promise.resolve()
}


function checkRedirect(locale) {
  if (ts.conf.live.is_mobile || !router.app.$store.getters['auth/currentAccount'].rol.some(v => ['admin', 'manager', 'admin_scp::organization::', 'manager_scp::organization::'].includes(v))) {
    return { name: 'me-dashboard', params: { locale: locale } }
  } else {
    return { name: 'manager-dashboard', params: { locale: locale } }
  }
}


router.beforeEach(async (to, from, next) => {
  /* Check locale and set new locale if required. */
  let locale = to.params.locale || to.query.locale || router.app.$store.getters['base/getLocale']
  if (!ts.conf.base.available_locales.includes(locale)) locale = router.app.$store.getters['base/getLocale']

  /* Await locale is loaded. */
  await checkLocaleLoaded(locale)

  /* Set requested locale. */
  checkLocaleSet(locale)

  /* Await userinfo. */
  await checkUserinfo(to)

  /* Check required authentication and authorization. */
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!router.app.$store.getters['auth/currentAccount']) {
      return next({ name: 'login', params: { locale: locale } })
    } else {
      const record = to.matched.filter(record => record.meta.authorize).pop()
      if (record) {
        const auth = typeof record.meta.authorize === 'function' ? record.meta.authorize(to.params) : record.meta.authorize
        if (!auth.filter(r => router.app.$store.getters['auth/currentAccount'].rol.includes(r)).length) {
          return next(checkRedirect(locale))
        }
      }
    }
  }

  /* Check if root route and redirect to right place. */
  if (to.name == 'root' || to.path == '/') {
    if (!router.app.$store.getters['auth/currentAccount']) {
      return next({ name: 'login', params: { locale: locale } })
    } else {
      return next(checkRedirect(locale))
    }
  }

  next({ params: { locale: locale } })
})


export default router
