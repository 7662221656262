import api   from '@client/services/api'
import axios from '@client/services/axios/interceptors'


export default {

  list(params, query, config) {
    return api.get(axios, '/manager/members', params, query, config)
  },

  get(params, query, config) {
    return api.get(axios, '/manager/members/:id', params, query, config)
  },

  create(params, payload, config) {
    return api.post(axios, '/manager/members', params, payload, config)
  },

  update(params, payload, config) {
    return api.update(axios, '/manager/members/:id', params, payload, config)
  },

  destroy(params, config) {
    return api.delete(axios, '/manager/members/:id', params, config)
  },

  board(params, query, config) {
    return api.get(axios, '/manager/members/:id/board', params, query, config)
  },

  searchAccounts(params, query, config) {
    return api.get(axios, '/manager/members/search_accounts', params, query, config)
  }

}
