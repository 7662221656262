import Vue       from 'vue'
import VueRemote from '@client/common/config/remote'


const tsconf = new VueRemote.Config({
  conf: {
    id: 'ts-app-data'
  }
})


export default tsconf


Vue.use(VueRemote)
