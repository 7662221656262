import api   from '@client/services/api'
import axios from '@client/services/axios/interceptors'


export default {

  login(payload, config) {
    return api.post(axios, '/account/login', {}, payload, config)
  },

  logout(config) {
    return api.delete(axios, '/account/logout', {}, config)
  },

  userinfo(config) {
    return api.get(axios, '/account/userinfo', {}, config)
  },

  panel(config) {
    return api.get(axios, '/account/panel', {}, config)
  }

}
