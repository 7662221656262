import { asyncLoadRouter, redirect } from '@client/router/helper'
import store    from '@client/store/frontend'
import me       from '@client/router/frontend/me'
import manager  from '@client/router/frontend/manager'
import account  from '@client/router/frontend/account'


const routes = [
  {
    path: '/:locale',
    name: 'root',
    component: () => import('@client/views/frontend'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: asyncLoadRouter('Login'),
        props: { action: 'login' }
      },
      {
        path: 'logout',
        name: 'logout',
        component: asyncLoadRouter('Logout'),
        props: { action: 'logout' }
      },
      {
        path: 'register',
        name: 'register',
        component: asyncLoadRouter('Register'),
        props: { action: 'register' }
      },
      ...me,
      ...manager,
      ...account
    ]
  },
  { path: '*',
    redirect: to => {
      return { name: 'root', params: { locale: store.getters['base/getLocale'] } }
    }
  }
]


export default routes
