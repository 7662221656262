import apiAccounts from '@client/services/api/manager/accounts'


export default {

  namespaced: true,
  actions: {
    getBoard: (_, payload) => {
      return apiAccounts.board(payload)
    }
  }

}
