import { asyncLoadRouter } from '@client/router/helper'


const organizations = [
  {
    path: 'organizations',
    component: asyncLoadRouter('manager/Organizations'),
    meta: {
      requiresAuth: true,
      authorize: ['admin', 'manager', 'admin_scp::organization::', 'manager_scp::organization::'],
      breadcrumb: i18n => [ { text: i18n.t('activerecord.models.organization.other'), to: { name: 'manager-organizations' } } ]
    },
    children: [
      {
        path: 'categories',
        component: asyncLoadRouter('manager/organizations/Categories'),
        meta: {
          authorize: ['admin'],
          breadcrumb: i18n => [ { text: i18n.t('activerecord.models.organization_category.other'), to: { name: 'manager-organization-categories' } } ]
        },
        children: [
          {
            path: '',
            name: 'manager-organization-categories',
            component: asyncLoadRouter('manager/organizations/categories/List'),
            meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.list') } ] },
            props: { action: 'list' },
          },
          {
            path: 'new',
            name: 'manager-organization-categories-new',
            component: asyncLoadRouter('manager/organizations/categories/Form'),
            meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.new'), to: { name: 'manager-organization-categories-new' } } ] },
            props: { action: 'create' }
          },
          {
            path: ':id/edit',
            name: 'manager-organization-categories-edit',
            component: asyncLoadRouter('manager/organizations/categories/Form'),
            meta: { breadcrumb: (i18n, params) => [ { text: `ID ${parseInt(params.id)}`, to: { name: 'manager-organization-categories-edit', params: { id: parseInt(params.id) } } },
                                             { text: i18n.t('shared.actions.edit') } ] },
            props: { action: 'update' }
          }
        ]
      },
      {
        path: '',
        name: 'manager-organizations',
        component: asyncLoadRouter('manager/organizations/List'),
        meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.list') } ] },
        props: { action: 'list' },
      },
      {
        path: 'new',
        name: 'manager-organizations-new',
        component: asyncLoadRouter('manager/organizations/New'),
        meta: {
          authorize: ['admin', 'manager'],
          breadcrumb: i18n => [ { text: i18n.t('shared.actions.new'), to: { name: 'manager-organizations-new' } } ]
        },
        props: { action: 'create' }
      },
      {
        path: ':organization_id',
        redirect: to => { return { name: 'manager-organizations-edit', params: { locale: to.params.locale, organization_id: parseInt(to.params.organization_id) } } },
        name: 'manager-organizations-show',
        component: asyncLoadRouter('manager/organizations/Show'),
        meta: { breadcrumb: (i18n, params) => [ { text: `ID ${parseInt(params.organization_id)}`, to: { name: 'manager-organizations-edit', params: { organization_id: parseInt(params.organization_id) } } } ] },
        props: { action: 'show' },
        children: [
          {
            path: 'edit',
            name: 'manager-organizations-edit',
            component: asyncLoadRouter('manager/organizations/Form'),
            meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.edit') } ] },
            props: { action: 'update' }
          },
          {
            path: 'profile',
            redirect: to => { return { name: 'manager-organizations-profile-edit', params: { locale: to.params.locale, organization_id: parseInt(to.params.organization_id) } } },
            component: asyncLoadRouter('manager/organizations/Profile'),
            meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('activerecord.models.organization_profile.one'), to: { name: 'manager-organizations-profile-edit', params: { organization_id: parseInt(params.organization_id) } } } ] },
            children: [
              {
                path: 'edit',
                name: 'manager-organizations-profile-edit',
                component: asyncLoadRouter('manager/organizations/profile/Form'),
                meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.edit') } ] },
                props: { action: 'update' }
              },
              {
                path: 'address/edit',
                name: 'manager-organizations-profile-address-edit',
                component: asyncLoadRouter('manager/organizations/profile/address/Form'),
                meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('activerecord.models.address.one'), to: { name: 'manager-organizations-profile-address-edit', params: { organization_id: parseInt(params.organization_id) } } },
                                                        { text: i18n.t('shared.actions.edit') } ] },
                props: { action: 'update' }
              },
              {
                path: 'contact/edit',
                name: 'manager-organizations-profile-contact-edit',
                component: asyncLoadRouter('manager/organizations/profile/contact/Form'),
                meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('activerecord.models.contact.one'), to: { name: 'manager-organizations-profile-contact-edit', params: { organization_id: parseInt(params.organization_id) } } },
                                                        { text: i18n.t('shared.actions.edit') } ] },
                props: { action: 'update' }
              },
            ]
          },
          {
            path: 'members',
            component: asyncLoadRouter('manager/organizations/Members'),
            meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('activerecord.models.member.one'), to: { name: 'manager-organizations-members', params: { organization_id: parseInt(params.organization_id) } } } ] },
            children: [
              {
                path: '',
                name: 'manager-organizations-members',
                component: asyncLoadRouter('manager/organizations/members/List'),
                meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.list') } ] },
                props: { action: 'list' }
              }
            ]
          },
          {
            path: 'allocations',
            component: asyncLoadRouter('manager/organizations/Allocations'),
            meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('activerecord.models.allocation.one'), to: { name: 'manager-organizations-allocations', params: { organization_id: parseInt(params.organization_id) } } } ] },
            children: [
              {
                path: '',
                name: 'manager-organizations-allocations',
                component: asyncLoadRouter('manager/organizations/allocations/List'),
                meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.list') } ] },
                props: { action: 'list' }
              },
              {
                path: 'new',
                name: 'manager-organizations-allocations-new',
                component: asyncLoadRouter('manager/organizations/allocations/Form'),
                meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('shared.actions.new'), to: { name: 'manager-organizations-allocations-new', params: { organization_id: parseInt(params.organization_id) } } } ] },
                props: { action: 'create' }
              },
              {
                path: ':id/edit',
                name: 'manager-organizations-allocations-edit',
                component: asyncLoadRouter('manager/organizations/allocations/Form'),
                meta: { breadcrumb: (i18n, params) => [ { text: `ID ${parseInt(params.id)}`, to: { name: 'manager-organizations-allocations-edit', params: { organization_id: parseInt(params.organization_id), id: parseInt(params.id) } } },
                                                        { text: i18n.t('shared.actions.edit') } ] },
                props: { action: 'update' }
              }
            ]
          },
          {
            path: 'rules',
            component: asyncLoadRouter('manager/organizations/Rules'),
            meta: {
              authorize: (params) => ['admin', 'manager', `admin_scp::organization::${parseInt(params.organization_id)}`],
              breadcrumb: (i18n, params) => [ { text: i18n.t('activerecord.models.rule.other'), to: { name: 'manager-organizations-rules-edit', params: { organization_id: parseInt(params.organization_id) } } } ]
            },
            children: [
              {
                path: 'edit',
                name: 'manager-organizations-rules-edit',
                component: asyncLoadRouter('manager/organizations/rules/Form'),
                meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.edit') } ] },
                props: { action: 'update' }
              }
            ]
          }
        ]
      }
    ]
  }
]


export default organizations
