import apiOrganizations from '@client/services/api/manager/organizations'


export default {

  namespaced: true,
  actions: {
    getBoard: (_, payload) => {
      return apiOrganizations.board(payload)
    }
  }

}
