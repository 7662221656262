/* Inspired by http://jasonwatmore.com/post/2018/09/10/vuejs-set-get-delete-reactive-nested-object-properties */

import Vue from 'vue'


const VueDeep = {

  install(Vue, options) {

    Vue.prototype.$getDeep = (obj, path) => {
      if (obj === undefined) return obj
      const props = path.split('.')
      const prop = props.shift()
      if (obj[prop] === undefined || obj[prop] === null) {
        return obj[prop]
      }
      if (!props.length) {
        return obj[prop]
      }
      return Vue.prototype.$getDeep(obj[prop], props.join('.'))
    }


    Vue.prototype.$setDeep = (obj, path, value, merge = true) => {
      if (obj === undefined) return obj
      const props = path.split('.')
      const prop = props.shift()
      if (!obj[prop]) {
        Vue.set(obj, prop, {})
      }
      if (!props.length) {
        if (merge && value && typeof value === 'object' && !Array.isArray(value)) {
          obj[prop] = { ...obj[prop], ...value }
        } else {
          obj[prop] = value
        }
        return obj
      }
      return Vue.prototype.$setDeep(obj[prop], props.join('.'), value)
    }


    Vue.prototype.$deleteDeep = (obj, path) => {
      if (obj === undefined) return obj
      const props = path.split('.')
      const prop = props.shift()
      if (obj[prop] === undefined) {
        return obj
      }
      if (!props.length) {
        Vue.delete(obj, prop)
        return obj
      }
      return Vue.prototype.$deleteDeep(obj[prop], props.join('.'))
    }


    Vue.prototype.$mergeDeep = (state, value, propName, ignoreNull = true) => {
    	if (
    		Object.prototype.toString.call(value) === "[object Object]" &&
    		(propName == null || state.hasOwnProperty(propName))
    	) {
    		const o = propName == null ? state : (state[propName] || Vue.set(state, propName, {}))
    		for (var prop in value) {
    			Vue.prototype.$mergeDeep(o, value[prop], prop, ignoreNull)
    		}
    		return
    	}
    	if (!ignoreNull || value !== null) Vue.set(state, propName, value)
    }

  }

}


export default VueDeep


Vue.use(VueDeep)
