import apiAccount from '@client/services/api/account'


export default {

  namespaced: true,
  actions: {
    getPanel: (_, payload) => {
      return apiAccount.panel()
    }
  }

}
