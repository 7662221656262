import Vue          from 'vue'
import VueNProgress from 'vue-nprogress'


Vue.use(VueNProgress)


const nprogress = new VueNProgress()


export default nprogress
