import apiCoupons from '@client/services/api/me/coupons'


export default {

  namespaced: true,
  actions: {
    getBoard: (_, payload) => {
      return apiCoupons.get(payload)
    },
    getCode: (_, payload) => {
      return apiCoupons.token(payload)
    }
  }

}
