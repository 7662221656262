import { asyncLoadRouter } from '@client/router/helper'


const coupons = [
  {
    path: 'coupons',
    component: asyncLoadRouter('me/Coupons'),
    meta: {
      authorize: ['subscriber'],
      breadcrumb: i18n => [ { text: i18n.t('activerecord.models.coupon.other'), to: { name: 'me-coupons' } } ]
    },
    children: [
      {
        path: '',
        name: 'me-coupons',
        component: asyncLoadRouter('me/coupons/List'),
        meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.list') } ] },
        props: { action: 'list' }
      },
      {
        path: 'scan',
        name: 'me-coupons-scan',
        component: asyncLoadRouter('me/coupons/Scan'),
        meta: {
          authorize: ['contributor', 'contributor_scp::organization::'],
          breadcrumb: i18n => [ { text: i18n.t('coupon.breadcrumbs.scan') } ],
          config: { breadcrumb: { remove: true, show: 'lg' } }
        }
      },
      {
        path: ':coupon_id',
        name: 'me-coupons-show',
        component: asyncLoadRouter('me/coupons/Show'),
        meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('shared.actions.show'), to: { name: 'me-coupons-show', params: { coupon_id: parseInt(params.coupon_id) } } } ] },
        props: { action: 'show' }
      },
      {
        path: ':coupon_id/token',
        name: 'me-coupons-show-code',
        component: asyncLoadRouter('me/coupons/show/Code'),
        meta: {
          breadcrumb: (i18n, params) => [ { text: i18n.t('coupon.breadcrumbs.token') } ],
          config: { breadcrumb: { remove: true, show: 'lg' },
                    footer: { remove: true,  show: 'lg' } }
        },
        props: { action: 'show' }
      }
    ]
  }
]


export default coupons
