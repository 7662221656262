import { asyncLoadRouter } from '@client/router/helper'


const account = [
  {
    path: 'account',
    redirect: to => { return { name: 'me-account-edit', params: { locale: to.params.locale } } },
    component: asyncLoadRouter('me/Account'),
    meta: { breadcrumb: i18n => [ { text: i18n.t('activerecord.models.account.one'), to: { name: 'me-account-edit' } } ] },
    children: [
      {
        path: 'edit',
        name: 'me-account-edit',
        component: asyncLoadRouter('me/account/Form'),
        meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.edit') } ] },
        props: { action: 'update' }
      },
      {
        path: 'person',
        redirect: to => { return { name: 'me-account-person-edit', params: { locale: to.params.locale } } },
        component: asyncLoadRouter('me/account/Person'),
        meta: { breadcrumb: i18n => [ { text: i18n.t('activerecord.models.person.one'), to: { name: 'me-account-person-edit' } } ] },
        children: [
          {
            path: 'edit',
            name: 'me-account-person-edit',
            component: asyncLoadRouter('me/account/person/Form'),
            meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.edit') } ] },
            props: { action: 'update' }
          },
          {
            path: 'address/edit',
            name: 'me-account-person-address-edit',
            component: asyncLoadRouter('me/account/person/address/Form'),
            meta: { breadcrumb: i18n => [ { text: i18n.t('activerecord.models.address.one'), to: { name: 'me-account-person-address-edit' } },
                                          { text: i18n.t('shared.actions.edit') } ] },
            props: { action: 'update' }
          },
          {
            path: 'contact/edit',
            name: 'me-account-person-contact-edit',
            component: asyncLoadRouter('me/account/person/contact/Form'),
            meta: { breadcrumb: i18n => [ { text: i18n.t('activerecord.models.contact.one'), to: { name: 'me-account-person-contact-edit' } },
                                          { text: i18n.t('shared.actions.edit') } ] },
            props: { action: 'update' }
          }
        ]
      }
    ]
  }
]


export default account
