import coupons       from '@client/store/frontend/me/coupons'
import organizations from '@client/store/frontend/me/organizations'
import apiMe         from '@client/services/api/me'


export default {

  namespaced: true,
  modules: {
    coupons,
    organizations
  },
  actions: {
    getBoard: (_, payload) => {
      return apiMe.board()
    }
  }

}
