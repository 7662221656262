import { asyncLoadRouter } from '@client/router/helper'


const accounts = [
  {
    path: 'accounts',
    component: asyncLoadRouter('manager/Accounts'),
    meta: {
      requiresAuth: true,
      authorize: ['admin'],
      breadcrumb: i18n => [ { text: i18n.t('activerecord.models.account.other'), to: { name: 'manager-accounts' } } ]
    },
    children: [
      {
        path: '',
        name: 'manager-accounts',
        component: asyncLoadRouter('manager/accounts/List'),
        meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.list') } ] },
        props: { action: 'list' }
      },
      {
        path: 'new',
        name: 'manager-accounts-new',
        component: asyncLoadRouter('manager/accounts/New'),
        meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.new'), to: { name: 'manager-accounts-new' } } ] },
        props: { action: 'create' }
      },
      {
        path: ':account_id',
        redirect: to => { return { name: 'manager-accounts-edit', params: { locale: to.params.locale, account_id: parseInt(to.params.account_id) } } },
        name: 'manager-accounts-show',
        component: asyncLoadRouter('manager/accounts/Show'),
        meta: { breadcrumb: (i18n, params) => [ { text: `ID ${parseInt(params.account_id)}`, to: { name: 'manager-accounts-edit', params: { account_id: parseInt(params.account_id) } } } ] },
        props: { action: 'show' },
        children: [
          {
            path: 'edit',
            name: 'manager-accounts-edit',
            component: asyncLoadRouter('manager/accounts/Form'),
            meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.edit') } ] },
            props: { action: 'update' }
          },
          {
            path: 'person',
            component: asyncLoadRouter('manager/accounts/Person'),
            meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('activerecord.models.person.one'), to: { name: 'manager-accounts-person-edit', params: { account_id: parseInt(params.account_id) } } } ] },
            children: [
              {
                path: 'edit',
                name: 'manager-accounts-person-edit',
                component: asyncLoadRouter('manager/accounts/person/Form'),
                meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.edit') } ] },
                props: { action: 'update' }
              },
              {
                path: 'address/edit',
                name: 'manager-accounts-person-address-edit',
                component: asyncLoadRouter('manager/accounts/person/address/Form'),
                meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('activerecord.models.address.one'), to: { name: 'manager-accounts-person-address-edit', params: { account_id: parseInt(params.account_id) } } },
                                                        { text: i18n.t('shared.actions.edit') } ] },
                props: { action: 'update' }
              },
              {
                path: 'contact/edit',
                name: 'manager-accounts-person-contact-edit',
                component: asyncLoadRouter('manager/accounts/person/contact/Form'),
                meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('activerecord.models.contact.one'), to: { name: 'manager-accounts-person-contact-edit', params: { account_id: parseInt(params.account_id) } } },
                                                        { text: i18n.t('shared.actions.edit') } ] },
                props: { action: 'update' }
              }
            ]
          },
          {
            path: 'rules',
            component: asyncLoadRouter('manager/accounts/Rules'),
            meta: { breadcrumb: (i18n, params) => [ { text: i18n.t('activerecord.models.rule.other'), to: { name: 'manager-accounts-rules-edit', params: { account_id: parseInt(params.account_id) } } } ] },
            children: [
              {
                path: 'edit',
                name: 'manager-accounts-rules-edit',
                component: asyncLoadRouter('manager/accounts/rules/Form'),
                meta: { breadcrumb: i18n => [ { text: i18n.t('shared.actions.edit') } ] },
                props: { action: 'update' }
              }
            ]
          }
        ]
      }
    ]
  }
]


export default accounts
