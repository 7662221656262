import Vue     from 'vue'
import VueI18n from 'vue-i18n'
import ts      from '@client/common/ts'


Vue.use(VueI18n)


const i18n = new VueI18n({
  silentTranslationWarn: true,
  fallbackLocale: ts.conf.base.default_locale,
  messages: {}
})


export default i18n
